// eslint-disable-next-line import/named
import { defaultDashboard, userDashboard } from './dashboard'
import { settingsAdmin, settingsCompanyAdmin, settingsUser } from './settings'
import games from './games'
import { USER_ROLE } from '@/constants/static.json'

// eslint-disable-next-line import/prefer-default-export
export const resolveMenu = userRoleId => {
  if (userRoleId === USER_ROLE.ADMIN) {
    return [...defaultDashboard, ...games, ...settingsAdmin]
  }
  if (userRoleId === USER_ROLE.COMPANY_ADMIN) {
    return [...defaultDashboard, ...games, ...settingsCompanyAdmin]
  }
  if (userRoleId === USER_ROLE.PLAYER) {
    return [...userDashboard, ...settingsUser]
  }
  return []
}
